export const jsonDataBlockRules = {
  code: 200,
  message: 'OK',
  data: {
    app_block_rules: [
      {
        id: '652f42883c15fcb3eff2808f',
        apps: [
          {
            package_name: '50680912-5cd4-4cd8-9257-79934ed5ea40',
            logo: 'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/03.png',
          },
        ],
        cycle: 62,
        block_start_time: '8:00',
        block_end_time: '18:00',
      },
      {
        id: '652f42883c15fcb3eff28090',
        apps: [
          {
            package_name: '8c9bff15-02cf-4a6f-8ea4-f48835bad055',
            logo: 'https://parentalguard-test.oss-cn-hongkong.aliyuncs.com/demo/images/applogo/11.png',
          },
        ],
        cycle: 127,
        block_start_time: '22:00',
        block_end_time: '6:00',
      },
    ],
    update_time: 1700124834,
  },
};
